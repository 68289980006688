import { lazy, Suspense } from 'react';
import './App.scss';
import 'rsuite/dist/rsuite.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import LoadingApp from './Page/LoadingPage/LoadingApp';
import AuthRoute from './AuthRoute';
import ScrollToTop from "./Helper/Helper_ScrollToTop";
import PackageJSON from "./../package.json";

const NotFoundPage = lazy(() => import('./Page/NotFoundPage/NotFoundPage'));

const MasterBanner = lazy(() => import('./AdminPage/MasterBanner/MasterBanner'));
const HomePage = lazy(() => import('./Page/HomePage/HomePage'));
// const AboutUsPage = lazy(() => import('./Page/AboutUsPage/AboutUsPage'));
const AboutUsPageV2 = lazy(() => import('./Page/AboutUsPageV2/AboutUsPage'));
const CompanyCategoriesPage = lazy(() => import('./Page/CompanyCategoriesPage/CompanyCategoriesPage'));
const ListCompanyPage = lazy(() => import('./Page/CompanyCategoriesPage/ListCompanyPage/ListCompanyPage'));
const DetailCompanyPage = lazy(() => import('./Page/CompanyCategoriesPage/DetailCompanyPage/DetailCompanyPage'));
const ListBrandPage = lazy(() => import('./Page/ListBrandPage/ListBrandPage'));
// const ListProductPage = lazy(() => import('./Page/ProductPage/ListProductPage/ListProductPage'));
const BrandCategoriesPage = lazy(() => import('./Page/BrandCategoriesPage/BrandCategoriesPage'));
const ListSelectedCategoryPage = lazy(() => import('./Page/BrandCategoriesPage/ListSelectedCategoryPage/ListSelectedCategoryPage'));
const DetailProductPage = lazy(() => import('./Page/ProductPage/DetailProductPage/DetailProductPage'));
const ListNewsPage = lazy(() => import('./Page/NewsPage/ListNewsPage/ListNewsPage'));
const DetailNewsPage = lazy(() => import('./Page/NewsPage/DetailNewsPage/DetailNewsPage'));
const ContactPage = lazy(() => import('./Page/ContactPage/ContactPage'));
const CareerPage = lazy(() => import('./Page/CareerPage/CareerPage'));
const DetailCareerPage = lazy(() => import('./Page/CareerPage/DetailCareerPage/DetailCareerPage'));

{/* Admin Panel / Page Route */}
const LoginPage = lazy(() => import('./AdminPage/Auth/LoginPage'));
const DashboardAdmin = lazy(() => import('./AdminPage/Dashboard/DashboardAdmin'));
const CreateProductAdmin = lazy(() => import('./AdminPage/MasterProduct/CreateProductAdmin'));
const CategoryProductAdmin = lazy(() => import('./AdminPage/MasterProduct/CategoryProduct/CategoryProductAdmin'));
const ListProductAdmin = lazy(() => import('./AdminPage/MasterProduct/ListProductAdmin'));
const ImportProductAdmin = lazy(() => import('./AdminPage/MasterProduct/ImportProductAdmin'));
const ImportProductSpecification = lazy(() => import('./AdminPage/MasterProduct/ImportProductSpecification'));
const UpdateImportProductAdmin = lazy(() => import('./AdminPage/MasterProduct/UpdateImportProductAdmin'));
const CreateNewsAdmin = lazy(() => import('./AdminPage/MasterNews/CreateNewsAdmin'));
const ListNewsAdmin = lazy(() => import('./AdminPage/MasterNews/ListNewsAdmin'));
const CategoryNewsAdmin = lazy(() => import('./AdminPage/MasterNews/CategoryNewsAdmin'));
const CreateCompanyAdmin = lazy(() => import('./AdminPage/MasterCompany/CreateCompanyAdmin'));
const ListCompanyAdmin = lazy(() => import('./AdminPage/MasterCompany/ListCompanyAdmin'));
const CategoryCompanyAdmin = lazy(() => import('./AdminPage/MasterCompany/CategoryCompanyAdmin'));
const CreateBrandAdmin = lazy(() => import('./AdminPage/MasterBrand/CreateBrandAdmin'));
const ListBrandAdmin = lazy(() => import('./AdminPage/MasterBrand/ListBrandAdmin'));
const CategoryBrandAdmin = lazy(() => import('./AdminPage/MasterBrand/CategoryBrandAdmin'));
const CreateContactAdmin = lazy(() => import('./AdminPage/MasterContact/CreateContactAdmin'));
const ListContactAdmin = lazy(() => import('./AdminPage/MasterContact/ListContactAdmin'));
const CreateCareerAdmin = lazy(() => import('./AdminPage/MasterCareer/CreateCareerAdmin'));
const ListCareerAdmin = lazy(() => import('./AdminPage/MasterCareer/ListCareerAdmin'));
const CategoryCareerAdmin = lazy(() => import('./AdminPage/MasterCareer/CategoryCareerAdmin'));

export const App = () => {
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <Suspense 
        // fallback={<LoadingApp/>}
        >
          <Router>
            <ScrollToTop />

            {process.env.REACT_APP_MODE === 'app' &&
              <Routes> 
                {/* <Route path="/" element={AuthRoute({Component: <HomePage/>})}/> */}
                <Route path="/" element={<HomePage/>}/>
                <Route path="/about-us" element={<AboutUsPageV2/>}/>
                <Route path="/company-categories" element={<CompanyCategoriesPage/>}/>
                <Route path="/list/company/:category" element={<ListCompanyPage/>}/>
                <Route path="/list/brand/:category" element={<ListSelectedCategoryPage/>}/>
                <Route path="/detail-company/:type/:id" element={<DetailCompanyPage/>}/>
                <Route path="/list-brand" element={<ListBrandPage/>}/>
                {/* <Route path="/list-product" element={<ListProductPage/>}/> */}
                <Route path="/brand-categories" element={<BrandCategoriesPage/>}/>
                <Route path="/detail-product/:id" element={<DetailProductPage/>}/>
                <Route path="/list-news/:category" element={<ListNewsPage/>}/>
                <Route path="/detail-news/:id" element={<DetailNewsPage/>}/>
                <Route path="/contact-us" element={<ContactPage/>}/>
                <Route path="/career" element={<CareerPage/>}/>
                <Route path="/detail-career/:id" element={<DetailCareerPage/>}/>
                <Route path="*" element={<NotFoundPage />}/>
              </Routes>
            }

            {process.env.REACT_APP_MODE === 'admin' &&
              <Routes> 
                {/* Admin Panel / Page Route */}
                <Route path="/" element={<LoginPage/>}/>
                <Route path="/admin/dashboard" element={AuthRoute({Component:<DashboardAdmin/>})}/>

                <Route path="/admin/category-product" element={AuthRoute({Component:<CategoryProductAdmin/>})} />
                <Route path="/admin/create-product" element={AuthRoute({Component:<CreateProductAdmin/>})} />
                <Route path="/admin/create-product/:id" element={AuthRoute({Component:<CreateProductAdmin/>})} />
                <Route path="/admin/list-product" element={AuthRoute({Component:<ListProductAdmin/>})} />
                <Route path="/admin/import-product" element={AuthRoute({Component:<ImportProductAdmin/>})} />
                <Route path="/admin/import-product-specification" element={AuthRoute({Component:<ImportProductSpecification/>})} />
                <Route path="/admin/update-import-product" element={AuthRoute({Component:<UpdateImportProductAdmin/>})} />

                <Route path="/admin/create-news" element={AuthRoute({Component:<CreateNewsAdmin/>})} />
                <Route path="/admin/create-news/:id" element={AuthRoute({Component:<CreateNewsAdmin/>})} />
                <Route path="/admin/list-news" element={AuthRoute({Component:<ListNewsAdmin/>})} />
                <Route path="/admin/category-news" element={AuthRoute({Component:<CategoryNewsAdmin/>})} />

                <Route path="/admin/create-company" element={AuthRoute({Component:<CreateCompanyAdmin/>})} />
                <Route path="/admin/create-company/:id" element={AuthRoute({Component:<CreateCompanyAdmin/>})} />
                <Route path="/admin/list-company" element={AuthRoute({Component:<ListCompanyAdmin/>})} />
                <Route path="/admin/category-company" element={AuthRoute({Component:<CategoryCompanyAdmin/>})} />
              
                <Route path="/admin/create-brand" element={AuthRoute({Component:<CreateBrandAdmin/>})} />
                <Route path="/admin/create-brand/:id" element={AuthRoute({Component:<CreateBrandAdmin/>})} />
                <Route path="/admin/list-brand" element={AuthRoute({Component:<ListBrandAdmin/>})} />
                <Route path="/admin/category-brand" element={AuthRoute({Component:<CategoryBrandAdmin/>})} />

                <Route path="/admin/create-contact" element={AuthRoute({Component:<CreateContactAdmin/>})} />
                <Route path="/admin/create-contact/:id" element={AuthRoute({Component:<CreateContactAdmin/>})} />
                <Route path="/admin/list-contact" element={AuthRoute({Component:<ListContactAdmin/>})} />

                <Route path="/admin/create-career" element={AuthRoute({Component:<CreateCareerAdmin/>})} />
                <Route path="/admin/create-career/:id" element={AuthRoute({Component:<CreateCareerAdmin/>})} />
                <Route path="/admin/list-career" element={AuthRoute({Component:<ListCareerAdmin/>})} />
                <Route path="/admin/category-career" element={AuthRoute({Component:<CategoryCareerAdmin/>})} />

                <Route path="/admin/master-banner" element={AuthRoute({Component:<MasterBanner/>})}/>
                <Route path="*" element={<NotFoundPage />}/>
              </Routes>
              }
             
          </Router>
      </Suspense>
      {process.env.NODE_ENV === "development" && <div className='development-notice'>Development Mode Build {PackageJSON.version}</div>}
    </div>
  );
}

export default App;
